import React from "react";

class LinePath extends React.Component {
  // LinePath has two states, the active state that's the currently being drawn
  // the completed state that has the control points rendered
  // when in the completed state, the control points should be rendered on either side of the path
  // 24px from the path point, perpendicular to the path
  // how would you find a perpendicular point to a drawn path?

  /*

  How to find the perpendicular line to the path drawn

  Read this to figure it out: https://www.mathsisfun.com/algebra/line-parallel-perpendicular.html

  1. Find the slope of the line drawn
  slope = (y₂ - y₁) / (x₂ - x₁)
  
  *slope is often referred to with a single letter m

  2. Solve for B for the drawn line
  y1 = mx1 + b
  3 = 2*3 + 


  */

  render() {
    let pathPoints = `M ${this.props.startingPoint[0]},${this.props.startingPoint[1]} C ${this.props.firstControlPoint[0]},${this.props.firstControlPoint[1]} ${this.props.secondControlPoint[0]},${this.props.secondControlPoint[1]} ${this.props.endPoint[0]},${this.props.endPoint[1]}`;

    return (
      <g>
        <path fill="none" stroke="#27AFD5" strokeWidth="1" d={pathPoints} />
      </g>
    );
  }
}

export default LinePath;