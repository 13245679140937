import React from "react";
import Layout from "../../components/layout-nogrid";
import Canvas from "../../components/pen/canvas";
import "./style.scss";

class Path extends React.Component {
  constructor(props) {
    super(props);

    this.handleMove = this.handleMove.bind(this);

    this.state = { pointerX: 0, pointerY: 0 };
  }

  handleMove(e) {
    this.setState({ pointerX: e.clientX, pointerY: e.clientY - 74 });
  }

  render() {
    return (
      <Layout>
        <div onPointerMove={this.handleMove}>
          <div width="100%" height="50px">
            <button className="clearButton clickable">Clear</button>
          </div>
          <Canvas x={this.state.pointerX} y={this.state.pointerY} />
        </div>
      </Layout>
    );
  }
}

export default Path;
