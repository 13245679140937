import React from "react";
import LinePath from "./linePath";

class Canvas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startingPoint: [],
      lines: [],
      pathInProgress: false
    };
  }

  handleSvgClick = (event) => {
    event.persist();
    event.preventDefault();

    this.placeDot(event.target, event.clientX, event.clientY - 78);

    // click to start a path
    // are we drawing a path currently?
    if (this.state.pathInProgress) {
      this.completePath([event.clientX, event.clientY - 78]);
    } else {
      this.startPath([event.clientX, event.clientY - 78]);
    }
  }

  startPath = (startPoint) => {
    this.setState({ startingPoint: startPoint, pathInProgress: true });
  }

  completePath = (endPoint) => {
    let startedPath = <LinePath startingPoint={this.state.startingPoint} firstControlPoint={this.state.startingPoint} secondControlPoint={endPoint} endPoint={endPoint} />;
    let newLines = Array.from(this.state.lines);
    newLines.push(startedPath);
    this.setState({ lines: newLines, pathInProgress: false });
  }

  removePath = () => {
    this.setState({ pathPoints: [] });
    let canvas = document.getElementById("pathCanvas");
    while (canvas.firstChild) {
      canvas.removeChild(canvas.firstChild);
    }
    this.setState({ startingPoint: [], endPoint: [] });
  }

  placeDot = (canvas, x, y) => {
    // create a new g SVG element
    let gSvg = document.createElementNS('http://www.w3.org/2000/svg', 'g');

    // create a new circle SVG element
    let clickedSpot = document.createElementNS('http://www.w3.org/2000/svg', 'circle');

    // set the attributes of the circle
    clickedSpot.setAttribute("cx", x);
    clickedSpot.setAttribute("cy", y);
    clickedSpot.setAttribute("r", 4);
    clickedSpot.setAttribute("fill", "#27AFD5");

    // add the circle as a child of g
    gSvg.appendChild(clickedSpot);

    // add g as a child of clicked SVG
    canvas.appendChild(gSvg);
  }

  render() {
    let inProgressPath = "";

    if (this.state.pathInProgress) {
      inProgressPath = <LinePath startingPoint={this.state.startingPoint} firstControlPoint={this.state.startingPoint} secondControlPoint={[this.props.x, this.props.y]} endPoint={[this.props.x, this.props.y]} />;
    } else {
      inProgressPath = "";
    }

    return (
      <div
        width="100%"
        height="100%"
        onClick={this.handleSvgClick}
      >
        <svg
          width="100%"
          height="100vh"
          id="pathCanvas"
        >
          {inProgressPath}
          {this.state.lines}
        </svg>
      </div>
    );
  }
}

export default Canvas;
